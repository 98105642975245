<template>
  <e-sidebar
    id="sidebar-form-tiered-price"
    :title="isEdit ? $t('Editar preço escalonado') : $t('Adicionar preço escalonado')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="onSaveItem"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="itemSidebarForm"
        name="itemSidebarForm"
      >
        <b-row>
          <b-col md="12">
            <e-store-combo
              id="filters-store"
              v-model="itemForm.storeId"
              :required="false"
              :placeholder="$t('Todas')"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="tiered_price_sidebar-price-table"
              v-model="itemForm.priceTableId"
              type="vue-select"
              class="required"
              validation="required"
              :label="$t('Tabela de preço')"
              :placeholder="$t('Selecione')"
              :options="priceTableOptions"
            />
          </b-col>
        </b-row>
        <FormulateInput
          v-slot="{ index }"
          ref="priceGroup"
          v-model="tieredPrices"
          name="tieredPrices"
          type="group"
          :repeatable="true"
          :add-label="$t('Adicionar faixa')"
          @before-remove="handleBeforeRemove"
          @repeatableRemoved="handleRemove"
        >
          <b-row>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-quantity-from-${index}`"
                name="quantityFrom"
                type="text-number"
                :disabled="index > 0"
                :value="getQuantityFrom(index)"
                class="required"
                :label="$t('Quantidade inicial')"
                validation="required"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-quantity-to-${index}`"
                name="quantityTo"
                type="text-number"
                :label="$t('Quantidade final')"
                @input="updateNextQuantityFrom(index)"
              />
            </b-col>
            <b-col md="4">
              <FormulateInput
                :id="`tiered_price_sidebar-price-${index}`"
                name="price"
                type="text-number"
                currency="R$"
                :precision="2"
                validation="required"
                class="required"
                :label="$t('Preço')"
                @input="validatePrice(index)"
              />
            </b-col>
          </b-row>
        </FormulateInput>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar, EStoreCombo } from '@/views/components'
import { authorizationRules, discountTypes, formulateHelper } from '@/mixins'

const getInitialTieredPriceForm = () => ({
  id: null,
  quantityFrom: null,
  quantityTo: null,
  price: null,
})

const getInitialItemForm = () => ({
  storeId: null,
  priceTableId: null,
})

export default {
  components: { BRow, BCol, ESidebar, EStoreCombo },

  mixins: [discountTypes, formulateHelper, authorizationRules],

  props: {
    storeId: {
      type: [String, Number],
      default: null,
    },
    priceTableId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      itemForm: getInitialItemForm(),
      showSidebar: false,
      fetching: false,
      saving: false,
      tieredPrices: [getInitialTieredPriceForm()],
      removedPrices: [],
    }
  },

  computed: {
    ...mapGetters('pages/catalog/products/productMaintain', ['priceTableOptions']),
    ...mapState('pages/catalog/products/productMaintain', ['priceTables']),
    isEdit() {
      return !!this.itemForm.id || !!this.itemForm.localId
    },
    activeTieredPrices: {
      get() {
        // Retorna apenas os itens que não foram deletados
        return this.tieredPrices.filter(item => !item.isDeleted)
      },
      set(newPrices) {
        // Atualiza os valores existentes, sem remover fisicamente os itens
        this.tieredPrices = newPrices
      },
    },
  },

  methods: {
    ...mapActions('pages/catalog/products/productMaintain', ['setTieredPrice']),
    async show(item) {
      this.cleanSidebar()
      this.showSidebar = true

      if (item) {
        this.itemForm = { ...item }
        this.tieredPrices = item.prices
      }
    },

    hide() {
      this.cleanSidebar()
      this.showSidebar = false
    },

    cleanSidebar() {
      this.itemForm = getInitialItemForm()
      this.tieredPrices = [getInitialTieredPriceForm()]
    },

    async onSaveItem() {
      try {
        this.saving = true
        this.$refs.itemSidebarForm.showErrors()
        if (this.$refs.itemSidebarForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }

        if (this.isEdit) {
          this.$emit('update', this.itemForm)
        } else {
          const tieredPrices = []
          this.tieredPrices.forEach(tieredPrice => {
            tieredPrices.push({
              id: tieredPrice.id,
              storeId: this.itemForm.storeId,
              priceTableId: this.itemForm.priceTableId,
              quantityFrom: tieredPrice.quantityFrom,
              quantityTo: tieredPrice.quantityTo,
              price: tieredPrice.price,
            })
          })
          this.setTieredPrice(tieredPrices)
          this.$emit('add', this.itemForm)
          this.cleanSidebar()
        }

        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    getQuantityFrom(index) {
      if (index === 0) return 1
      return (this.tieredPrices[index - 1]?.quantityTo ?? 0) + 1
    },

    updateNextQuantityFrom(index) {
      if (index < this.tieredPrices.length - 1) {
        this.tieredPrices[index + 1].quantityFrom = this.tieredPrices[index].quantityTo + 1
      }
    },

    validatePrice(index) {
      if (index > 0) {
        const previousPrice = this.tieredPrices[index - 1]?.price
        const currentPrice = this.tieredPrices[index]?.price

        if (currentPrice > previousPrice) {
          // Se o preço atual for maior que o anterior, defina como o mesmo valor do anterior
          this.tieredPrices[index].price = previousPrice
          this.showWarning({ message: 'O preço não pode ser maior que o do item anterior.' })
        }
      }
    },
    handleBeforeRemove(item, index, remove) {
      // const realIndex = this.tieredPrices.findIndex(
      //   price => price.quantityFrom === this.filteredPrices[index].quantityFrom
      // )
      // Intercepta o comportamento de remoção e marca como isDeleted
      this.tieredPrices[index].isDeleted = true
      // Impede a remoção física do item
      remove(false) // Não remove o item fisicamente do array
    },
    // eslint-disable-next-line no-unused-vars
    handleRemove(item, a, b, c) {
      this.removedPrices.push(item)
    },
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 800;
}
</style>
