<template>
  <div>
    <b-table
      ref="priceTable"
      :key="tableKey"
      class="table-no-minheight bordered"
      show-empty
      responsive
      striped
      :fields="fields"
      :items="localSkuPrices.filter((p) => !p.isDeleted)"
    >
      <template #thead-top>
        <b-tr>
          <b-th
            colspan="2"
            style="background-color: transparent; border: 0"
          >
            <span class="sr-only">Ações e Loja</span>
          </b-th>
          <b-th
            variant="primary"
            :colspan="priceTables.length || 1"
          >
            {{ $t('Tabelas de Preços') }}
          </b-th>
        </b-tr>
      </template>
      <template #cell(action)="row">
        <e-grid-actions
          v-if="!isReadOnly"
          :show-update="false"
          :show-delete="row.item.storeId !== -1"
          @delete="deletePrice(row)"
        />
        <span v-if="row.item.storeId === -1 || isReadOnly">-</span>
      </template>
      <template #cell(variation)="row">
        <div v-if="!row.item.variations.length">
          {{ $t('Sem variação') }}
        </div>
        <div
          v-for="variation in row.item.variations"
          v-else
          :key="variation.variationId"
        >
          <b>{{ variation.variationName }}:</b>&nbsp;
          <span>{{
            variation.variationValue && variation.variationValue.value
              ? variation.variationValue.value
              : variation.variationOpenValue
          }}</span>
        </div>
      </template>
      <template
        v-for="priceTable in priceTables"
        #[`cell(priceTable_${priceTable.id})`]="data"
      >
        <FormulateInput
          :id="`price-table-${data.item.storeId}-${skuIndex}-${priceTable.id}`"
          :key="priceTable.id"
          v-model="data.item[data.field.key]"
          :type="isReadOnly ? 'label' : 'text-number'"
          filter="currency"
          currency="R$"
          :precision="2"
          :validation="
            requirePrice && priceTable.isDefault && data.item.storeId === -1
              ? 'required|min:0.01'
              : ''
          "
          :validation-messages="{
            min: $t('O preço geral para esta tabela é obrigatório'),
          }"
          @input="data.item[`${data.field.key}_changed`] = true"
        />
        <!-- <FormulateInput
          :key="priceTable.id"
          v-model="data.item[data.field.key]"
          style="background-color: transparent !important; border: 0"
          class="transparent-input"
          type="text"
        /> -->
        <!-- Some complicated rendering logic here -->
      </template>
    </b-table>
    <b-row v-if="!isReadOnly">
      <b-col
        md="12"
        class="text-right"
      >
        <e-button
          class="my-1"
          variant="primary"
          icon="plus-circle"
          :text="$t('Adicionar preço por Loja')"
          @click="addStorePrice"
        />
      </b-col>
    </b-row>
    <b-modal
      id="modal-store"
      v-model="showAddStore"
      :title="$t('Adicionar preço por loja')"
      centered
      no-close-on-backdrop
      hide-header-close
      hide-footer
      size="sm"
    >
      <FormulateForm
        ref="formAddStore"
        name="formAddStore"
        @submit="confirmAddStorePrice"
      >
        <b-row align-h="center">
          <b-col
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              :id="`price-table-store-to-add-${skuIndex}`"
              v-model="storeToAdd"
              type="vue-select"
              class="required"
              validation="required"
              :label="$t('Loja')"
              :placeholder="$t('Selecione')"
              :options="storeOptions"
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col class="d-flex justify-content-end">
            <e-button
              class="mr-1"
              :text="$t('Cancelar')"
              variant="danger"
              @click="showAddStore = false"
            />
            <e-button
              variant="primary"
              type="submit"
              :text="$t('Confirmar')"
            />
          </b-col>
        </b-row>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { BTable, BRow, BCol, BTr, BTh } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { EGridActions, EButton } from '@/views/components'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BTr,
    BTh,
    EGridActions,
    EButton,
  },

  mixins: [],

  props: {
    skuIndex: {
      type: Number,
      default: 0,
    },
    requirePrice: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      tableKey: 0,
      variationFormData: {},
      showSidebar: false,
      saving: false,
      isUpdate: false,
      updateIndex: null,
      variantsFields: [],
      localSkuPrices: [],
      showAddStore: false,
      storeToAdd: '',
    }
  },

  computed: {
    ...mapState('pages/catalog/products/productMaintain', ['product', 'priceTables', 'isReadOnly']),
    ...mapGetters('pages/catalog/products/productMaintain', ['skuPriceTables']),
    ...mapGetters('app', ['storeOptions']),
    localSkuPriceTables() {
      return this.skuPriceTables(this.skuIndex)
    },
    fixedFields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thStyle: 'width: 100px;',
          tdClass: 'text-center',
        },
        {
          label: this.$t('Loja'),
          key: 'storeId',
          class: 'text-left',
          formatter: (value, key, item) =>
            this.$options.filters.storeName({ id: item.storeId, name: item.storeName }),
        },
      ]
    },
    priceTableFields() {
      return this.priceTables.map(pt => ({
        label: pt.name,
        key: `priceTable_${pt.id}`,
        tdClass: 'text-right',
        thStyle: 'width: 120px;',
        formatter: value => this.$options.filters.currency(value, '-'),
      }))
    },
    fields() {
      return [...this.fixedFields, ...this.priceTableFields]
    },
  },

  watch: {
    localSkuPriceTables(val) {
      if (val?.length) {
        this.localSkuPrices = val
      } else {
        const skuPrice = {
          storeId: -1,
          storeName: 'Geral',
        }
        this.priceTables.forEach(pt => {
          skuPrice[`priceTable_${pt.id}`] = 0
        })
        this.localSkuPrices = [skuPrice]
      }
    },
  },

  mounted() {},

  methods: {
    ...mapActions('pages/catalog/products/productMaintain', ['getSkuPricesTable']),
    deletePrice(row) {
      this.priceTables.forEach(pt => {
        // eslint-disable-next-line no-param-reassign
        row.item[`priceTable_${pt.id}`] = 0
        // eslint-disable-next-line no-param-reassign
        row.item[`priceTable_${pt.id}_changed`] = true
      })
      // eslint-disable-next-line no-param-reassign
      row.item.isDeleted = true
      this.$refs.priceTable.refresh()
      this.tableKey = Math.random()
      // this.$nextTick(() => {})
    },
    getNewPrices() {
      const newPrices = []
      this.localSkuPrices.forEach(pt => {
        Object.keys(pt)
          .filter(key => key.startsWith('priceTable_') && key.endsWith('changed'))
          .forEach(key => {
            // console.log(key.replace('priceTable_', '').replace('_changed', ''))
            const price = pt[key.replace('_changed', '')]
            newPrices.push({
              priceTableId: parseInt(key.replace('priceTable_', '').replace('_changed', ''), 10),
              skuId: this.product.skus[this.skuIndex].id,
              storeId: pt.storeId > 0 ? parseInt(pt.storeId, 10) : undefined,
              price: (price === '' ? null : price) ?? 0,
            })
          })
      })
      return newPrices
    },
    addStorePrice() {
      this.showAddStore = true
    },
    confirmAddStorePrice() {
      if (!this.$refs.formAddStore.isValid) {
        this.showInvalidDataMessage()
        return
      }
      const skuPrice = {
        storeId: this.storeToAdd,
        storeName: this.storeOptions.find(store => store.value === this.storeToAdd).tradingName,
      }
      this.priceTables.forEach(pt => {
        skuPrice[`priceTable_${pt.id}`] = 0
      })
      this.localSkuPrices.push(skuPrice)
      this.showAddStore = false
      this.storeToAdd = undefined
    },
  },
}
</script>
