var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        key: _vm.tableKey,
        ref: "priceTable",
        staticClass: "table-no-minheight bordered",
        attrs: {
          "show-empty": "",
          responsive: "",
          striped: "",
          fields: _vm.fields,
          items: _vm.tieredPricesFormatted.filter(function (p) {
            return !p.isDeleted
          }),
        },
        scopedSlots: _vm._u([
          {
            key: "cell(action)",
            fn: function (row) {
              return [
                !_vm.isReadOnly
                  ? _c("e-grid-actions", {
                      attrs: { "show-update": true, "show-delete": true },
                      on: {
                        delete: function ($event) {
                          return _vm.deletePrice(row)
                        },
                        update: function ($event) {
                          return _vm.updatePrice(row)
                        },
                      },
                    })
                  : _vm._e(),
                row.item.storeId === -1 || _vm.isReadOnly
                  ? _c("span", [_vm._v("-")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(prices)",
            fn: function (row) {
              return [
                _c("span", { domProps: { innerHTML: _vm._s(row.value) } }),
              ]
            },
          },
        ]),
      }),
      !_vm.isReadOnly
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { md: "12" } },
                [
                  _c("e-button", {
                    staticClass: "my-1",
                    attrs: {
                      variant: "primary",
                      icon: "plus-circle",
                      text: _vm.$t("Adicionar preço escalonado"),
                    },
                    on: { click: _vm.addTieredPrice },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("tiered-price-sidebar", { ref: "tieredPriceSidebar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }