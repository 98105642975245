var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.invoiceProductName
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product-invoice-product-name",
                      label: _vm.$t("Produto da Nota Fiscal"),
                      type: "label",
                    },
                    model: {
                      value: _vm.invoiceProductName,
                      callback: function ($$v) {
                        _vm.invoiceProductName = $$v
                      },
                      expression: "invoiceProductName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product-invoice-product-cost",
                      label: _vm.$t("Valor de Custo"),
                      filter: "currency",
                      type: "label",
                    },
                    model: {
                      value: _vm.invoiceProductCost,
                      callback: function ($$v) {
                        _vm.invoiceProductCost = $$v
                      },
                      expression: "invoiceProductCost",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "product-brand-id",
                  label: _vm.$t("Marca"),
                  type: _vm.isReadOnly ? "label" : "select-with-button",
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboBrands,
                  validation: "required",
                  "button-permission": _vm.$can("Create", "Brand"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showAddSideBar("brand")
                  },
                },
                model: {
                  value: _vm.product.brandId,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "brandId", $$v)
                  },
                  expression: "product.brandId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                ref: "groupCombo",
                staticClass: "required",
                attrs: {
                  id: "product-group-id",
                  label: _vm.$t("Grupo"),
                  type: _vm.isReadOnly ? "label" : "select-with-button",
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboGroups,
                  validation: "required",
                  "button-permission": _vm.$can("Create", "ProductGroup"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showGroupSideBar()
                  },
                },
                model: {
                  value: _vm.product.groupId,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "groupId", $$v)
                  },
                  expression: "product.groupId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "product-subgroup-id",
                  type: _vm.isReadOnly ? "label" : "select-with-button",
                  label: _vm.$t("Subgrupo"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboSubgroups,
                  validation: "required",
                  disabled: !_vm.product.groupId,
                  "button-permission": _vm.$can("Create", "ProductSubgroup"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showAddSideBar("subgroup", {
                      groupId: _vm.product.group,
                    })
                  },
                },
                model: {
                  value: _vm.product.subgroupId,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "subgroupId", $$v)
                  },
                  expression: "product.subgroupId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "product-product-line-id",
                  type: _vm.isReadOnly ? "label" : "select-with-button",
                  label: _vm.$t("Linha"),
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboLines,
                  validation: "required",
                  "button-permission": _vm.$can("Create", "ProductLine"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showAddSideBar("line")
                  },
                },
                model: {
                  value: _vm.product.productLineId,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "productLineId", $$v)
                  },
                  expression: "product.productLineId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "product-department-id",
                  label: _vm.$t("Departamento"),
                  type: _vm.isReadOnly ? "label" : "select-with-button",
                  placeholder: _vm.$t("Selecione"),
                  options: _vm.getComboDepartments,
                  validation: "required",
                  "button-permission": _vm.$can("Create", "ProductDepartment"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showAddSideBar("department")
                  },
                },
                model: {
                  value: _vm.product.departmentId,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "departmentId", $$v)
                  },
                  expression: "product.departmentId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "product-product-unit-id",
                  label: _vm.$t("Unidade"),
                  type: _vm.isReadOnly ? "label" : "select-with-button",
                  placeholder: _vm.$t("Selecione"),
                  instruction: _vm.$t(
                    "Classificação da unidade de medida do produto"
                  ),
                  options: _vm.getComboUnits,
                  validation: "required",
                  "button-permission": _vm.$can("Create", "UnitOfMeasurement"),
                },
                on: {
                  click: function ($event) {
                    return _vm.showAddSideBar("unit")
                  },
                },
                model: {
                  value: _vm.product.productUnitId,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "productUnitId", $$v)
                  },
                  expression: "product.productUnitId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "product-product-type",
                  label: _vm.$t("Tipo"),
                  type: _vm.isReadOnly ? "label" : "vue-select",
                  placeholder: "Selecione",
                  validation: "required",
                  options: _vm.productTypes(),
                },
                model: {
                  value: _vm.product.productType,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "productType", $$v)
                  },
                  expression: "product.productType",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                staticClass: "required",
                attrs: {
                  id: "product-classification",
                  label: _vm.$t("Classificação"),
                  type: _vm.isReadOnly ? "label" : "vue-select",
                  placeholder: _vm.$t("Selecione"),
                  validation: "required",
                  options: _vm.productClassifications(),
                },
                model: {
                  value: _vm.product.classification,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "classification", $$v)
                  },
                  expression: "product.classification",
                },
              }),
            ],
            1
          ),
          _vm.isSaleClassification
            ? _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product-lending-product-associated-id",
                      type: _vm.isReadOnly ? "label" : "select-searchable",
                      label: _vm.$t("Comodato Associado"),
                      instructions: [
                        {
                          text: _vm.$t(
                            "Produto comodato que será adicionado automaticamente a toda venda deste produto"
                          ),
                        },
                        {
                          text: _vm.$t(
                            "Digite pelo menos 3 numeros ou letras para iniciar a pesquisa"
                          ),
                        },
                      ],
                      placeholder: _vm.$t("Digite para pesquisar"),
                      options: _vm.lendingProducts,
                      autofocus: "",
                    },
                    on: { fetchSearch: _vm.lendingProductsSearch },
                    model: {
                      value: _vm.product.lendingProductAssociatedId,
                      callback: function ($$v) {
                        _vm.$set(_vm.product, "lendingProductAssociatedId", $$v)
                      },
                      expression: "product.lendingProductAssociatedId",
                    },
                  }),
                ],
                1
              )
            : _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "product-sale-product-contractual-fine",
                      label: _vm.$t("Valor indenização por danos"),
                      placeholder: _vm.$t("Valor indenização"),
                      instruction: _vm.$t(
                        "Para aluguel e comodato, caso o produto esteja danificado na devolução ou não foi devolvido, será gerada uma venda do item no valor aqui configurado"
                      ),
                      type: _vm.isReadOnly ? "label" : "text-number",
                      filter: "currency",
                      currency: "R$",
                      precision: 3,
                    },
                    model: {
                      value: _vm.product.contractualFine,
                      callback: function ($$v) {
                        _vm.$set(_vm.product, "contractualFine", $$v)
                      },
                      expression: "product.contractualFine",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _vm.showMililiters
            ? _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    staticClass: "required",
                    attrs: {
                      id: "product-milliliters",
                      type: _vm.isReadOnly ? "label" : "text-number",
                      validation: "required|min:1|max:100000|number",
                      label: _vm.$t("Mililitros"),
                      maxlength: "6",
                      instruction: _vm.$t(
                        "Quantidade de mililitros ofertados por unidade"
                      ),
                    },
                    model: {
                      value: _vm.product.milliliters,
                      callback: function ($$v) {
                        _vm.$set(_vm.product, "milliliters", _vm._n($$v))
                      },
                      expression: "product.milliliters",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "product-active-online",
                  type: _vm.isReadOnly ? "status-badge" : "switch",
                  "badge-type": "yesNo",
                  label: _vm.$t("Vender no Site?"),
                },
                model: {
                  value: _vm.product.activeOnline,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "activeOnline", $$v)
                  },
                  expression: "product.activeOnline",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "product-customer-required",
                  type: _vm.isReadOnly ? "status-badge" : "switch",
                  "badge-type": "yesNo",
                  label: _vm.$t("Obriga Cliente?"),
                  instruction: _vm.$t(
                    "Quando selecionado, caso este produto esteja na venda, será obrigatório informar os dados do cliente"
                  ),
                },
                model: {
                  value: _vm.product.customerRequired,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "customerRequired", $$v)
                  },
                  expression: "product.customerRequired",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "product-featured",
                  type: _vm.isReadOnly ? "status-badge" : "switch",
                  "badge-type": "yesNo",
                  label: _vm.$t("Destacar Produto?"),
                  instruction: _vm.$t(
                    "Quando selecionado, o produto será destacado para venda nos canais online"
                  ),
                },
                model: {
                  value: _vm.product.featuredProduct,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "featuredProduct", $$v)
                  },
                  expression: "product.featuredProduct",
                },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { md: "3" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "product-integrate-with-beer-cooler",
                  type: _vm.isReadOnly ? "status-badge" : "switch",
                  "badge-type": "yesNo",
                  label: _vm.$t("Integra Quiosque?"),
                  instructions: [
                    {
                      text: _vm.$t(
                        "ATENÇÃO: Não marque esta opção para o produto de Cartão, utilize apenas para os chopes que serão instalados nas torneiras"
                      ),
                    },
                    {
                      text: _vm.$t(
                        "Quando selecionado, este produto é integrado para que possa ser ofertado na chopeira das lojas do tipo Quiosque"
                      ),
                    },
                  ],
                },
                model: {
                  value: _vm.product.integrateWithBeerCooler,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "integrateWithBeerCooler", $$v)
                  },
                  expression: "product.integrateWithBeerCooler",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "product-active-b2b",
                  type: _vm.isReadOnly ? "status-badge" : "switch",
                  "badge-type": "yesNo",
                  label: _vm.$t("Vender no B2B?"),
                  instruction: _vm.$t(
                    "Quando selecionado, o produto será disponibilizado para venda B2B"
                  ),
                },
                model: {
                  value: _vm.product.activeB2B,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "activeB2B", $$v)
                  },
                  expression: "product.activeB2B",
                },
              }),
            ],
            1
          ),
          _vm.product.activeB2B
            ? _c(
                "b-col",
                { attrs: { md: "3" } },
                [
                  _c("FormulateInput", {
                    class: _vm.product.activeB2B ? "required" : "",
                    attrs: {
                      id: "product-b2b-quantity",
                      type: _vm.isReadOnly ? "label" : "text-number",
                      label: _vm.$t("Qtd. embalagem B2B"),
                      instruction: _vm.$t(
                        "Quantidade que vem na embalagem para venda B2B. Exemplo, para long neck, o pack vem com 6 unidades. Esta é a quantidade mínima na venda B2B e também o múltiplo que será usado (não será permitido quantidades quebradas, apenas múltiplos da quantidade informada neste campo)."
                      ),
                      validation: _vm.product.activeB2B ? "required" : "",
                    },
                    model: {
                      value: _vm.product.unitsPerPack,
                      callback: function ($$v) {
                        _vm.$set(_vm.product, "unitsPerPack", $$v)
                      },
                      expression: "product.unitsPerPack",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c("FormulateInput", {
                attrs: {
                  id: "product-tags",
                  type: _vm.isReadOnly ? "label" : "tags",
                  label: _vm.$t("Tags"),
                  placeholder: _vm.$t("Digite a tag e tecle Enter"),
                  size: "lg",
                  "tag-variant": "secondary",
                  instruction: _vm.$t("Digite a tag e tecle Enter"),
                  multiple: "",
                },
                model: {
                  value: _vm.product.tags,
                  callback: function ($$v) {
                    _vm.$set(_vm.product, "tags", $$v)
                  },
                  expression: "product.tags",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("product-group-sidebar", {
        ref: "groupSidebar",
        on: { save: _vm.groupCreated },
      }),
      _c("product-subgroup-sidebar", {
        ref: "subgroupSidebar",
        on: { save: _vm.subgroupCreated },
      }),
      _c("brand-sidebar", {
        ref: "brandSidebar",
        on: { save: _vm.brandCreated },
      }),
      _c("product-department-sidebar", {
        ref: "departmentSidebar",
        on: { save: _vm.departmentCreated },
      }),
      _c("product-line-sidebar", {
        ref: "lineSidebar",
        on: { save: _vm.lineCreated },
      }),
      _c("product-unit-sidebar", {
        ref: "unitSidebar",
        on: { save: _vm.unitCreated },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }