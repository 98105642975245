var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        key: _vm.tableKey,
        ref: "priceTable",
        staticClass: "table-no-minheight bordered",
        attrs: {
          "show-empty": "",
          responsive: "",
          striped: "",
          fields: _vm.fields,
          items: _vm.localSkuPrices.filter(function (p) {
            return !p.isDeleted
          }),
        },
        scopedSlots: _vm._u(
          [
            {
              key: "thead-top",
              fn: function () {
                return [
                  _c(
                    "b-tr",
                    [
                      _c(
                        "b-th",
                        {
                          staticStyle: {
                            "background-color": "transparent",
                            border: "0",
                          },
                          attrs: { colspan: "2" },
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Ações e Loja"),
                          ]),
                        ]
                      ),
                      _c(
                        "b-th",
                        {
                          attrs: {
                            variant: "primary",
                            colspan: _vm.priceTables.length || 1,
                          },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Tabelas de Preços")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "cell(action)",
              fn: function (row) {
                return [
                  !_vm.isReadOnly
                    ? _c("e-grid-actions", {
                        attrs: {
                          "show-update": false,
                          "show-delete": row.item.storeId !== -1,
                        },
                        on: {
                          delete: function ($event) {
                            return _vm.deletePrice(row)
                          },
                        },
                      })
                    : _vm._e(),
                  row.item.storeId === -1 || _vm.isReadOnly
                    ? _c("span", [_vm._v("-")])
                    : _vm._e(),
                ]
              },
            },
            {
              key: "cell(variation)",
              fn: function (row) {
                return [
                  !row.item.variations.length
                    ? _c("div", [
                        _vm._v(" " + _vm._s(_vm.$t("Sem variação")) + " "),
                      ])
                    : _vm._l(row.item.variations, function (variation) {
                        return _c("div", { key: variation.variationId }, [
                          _c("b", [
                            _vm._v(_vm._s(variation.variationName) + ":"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                variation.variationValue &&
                                  variation.variationValue.value
                                  ? variation.variationValue.value
                                  : variation.variationOpenValue
                              )
                            ),
                          ]),
                        ])
                      }),
                ]
              },
            },
            _vm._l(_vm.priceTables, function (priceTable) {
              return {
                key: "cell(priceTable_" + priceTable.id + ")",
                fn: function (data) {
                  return [
                    _c("FormulateInput", {
                      key: priceTable.id,
                      attrs: {
                        id:
                          "price-table-" +
                          data.item.storeId +
                          "-" +
                          _vm.skuIndex +
                          "-" +
                          priceTable.id,
                        type: _vm.isReadOnly ? "label" : "text-number",
                        filter: "currency",
                        currency: "R$",
                        precision: 2,
                        validation:
                          _vm.requirePrice &&
                          priceTable.isDefault &&
                          data.item.storeId === -1
                            ? "required|min:0.01"
                            : "",
                        "validation-messages": {
                          min: _vm.$t(
                            "O preço geral para esta tabela é obrigatório"
                          ),
                        },
                      },
                      on: {
                        input: function ($event) {
                          data.item[data.field.key + "_changed"] = true
                        },
                      },
                      model: {
                        value: data.item[data.field.key],
                        callback: function ($$v) {
                          _vm.$set(data.item, data.field.key, $$v)
                        },
                        expression: "data.item[data.field.key]",
                      },
                    }),
                  ]
                },
              }
            }),
          ],
          null,
          true
        ),
      }),
      !_vm.isReadOnly
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { md: "12" } },
                [
                  _c("e-button", {
                    staticClass: "my-1",
                    attrs: {
                      variant: "primary",
                      icon: "plus-circle",
                      text: _vm.$t("Adicionar preço por Loja"),
                    },
                    on: { click: _vm.addStorePrice },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-store",
            title: _vm.$t("Adicionar preço por loja"),
            centered: "",
            "no-close-on-backdrop": "",
            "hide-header-close": "",
            "hide-footer": "",
            size: "sm",
          },
          model: {
            value: _vm.showAddStore,
            callback: function ($$v) {
              _vm.showAddStore = $$v
            },
            expression: "showAddStore",
          },
        },
        [
          _c(
            "FormulateForm",
            {
              ref: "formAddStore",
              attrs: { name: "formAddStore" },
              on: { submit: _vm.confirmAddStorePrice },
            },
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "required",
                        attrs: {
                          id: "price-table-store-to-add-" + _vm.skuIndex,
                          type: "vue-select",
                          validation: "required",
                          label: _vm.$t("Loja"),
                          placeholder: _vm.$t("Selecione"),
                          options: _vm.storeOptions,
                        },
                        model: {
                          value: _vm.storeToAdd,
                          callback: function ($$v) {
                            _vm.storeToAdd = $$v
                          },
                          expression: "storeToAdd",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: { text: _vm.$t("Cancelar"), variant: "danger" },
                        on: {
                          click: function ($event) {
                            _vm.showAddStore = false
                          },
                        },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          type: "submit",
                          text: _vm.$t("Confirmar"),
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }