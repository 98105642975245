<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card-actions
          action-collapse
          :title="$t('Preços')"
          :show-loading="fetching"
        >
          <div
            v-for="(sku, index) in product.skus"
            :key="index"
            :sku="sku"
          >
            <price-table
              :ref="`priceTable_${index}`"
              :sku-index="index"
              :require-price="false"
            />
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-card-actions
          action-collapse
          :title="$t('Preços escalonados')"
          :show-loading="fetching"
        >
          <div
            v-for="(sku, index) in product.skus"
            :key="index"
            :sku="sku"
          >
            <tiered-price-table :ref="`tieredPriceTable_${index}`" />
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import BCardActions from '@/@core/components/b-card-actions/BCardActions.vue'
import PriceTable from './PriceTable.vue'
import TieredPriceTable from './TieredPriceTable.vue'

export default {
  name: 'VariationForm',

  components: {
    BRow,
    BCol,
    PriceTable,
    BCardActions,
    TieredPriceTable,
  },

  mixins: [],

  props: {
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      variationFormData: {},
      showSidebar: false,
      saving: false,
      isUpdate: false,
      updateIndex: null,
      variantsFields: [],
      skuIndex: -1,
      localSkuPrices: [],
    }
  },

  computed: {
    ...mapState('pages/catalog/products/productMaintain', [
      'product',
      'selectedVariants',
      'variationsList',
      'variationByName',
      'skuPrices',
      'priceTables',
    ]),
    ...mapGetters('pages/catalog/products/productMaintain', ['selectedVariants']),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Variação'),
          key: 'variation',
          // class: 'text-center',
          // thStyle: { width: '250px' },
        },
        {
          label: this.$t('Preços'),
          key: 'price',
          formatter: value => this.$options.filters.currency(value),
          tdClass: 'text-left',
          thStyle: { width: '180px' },
        },
      ]
    },
    // selected: {
    //   get() {
    //     return this.selectedVariants
    //   },
    //   set(value) {
    //     return this.setSelectedVariants(value)
    //   },
    // },

    getVariantsSelected() {
      return this.variationsList.filter(item =>
        // eslint-disable-next-line eqeqeq
        this.selectedVariants.some(id => id == item.id)
      )
    },
  },

  watch: {
    // selected(newVals, oldVals) {
    //   const hasAddedVal = oldVals.length < newVals.length
    //   if (hasAddedVal) {
    //     const nameAdded = newVals[newVals.length - 1]
    //     const variantObj = this.variationsList.find(vari => vari.name === nameAdded)
    //     this.addTableField(variantObj.name, variantObj.label)
    //   } else {
    //     const nameRemoved = oldVals.find(oldVal => !newVals.some(newVal => oldVal === newVal))
    //     this.removeTableField(nameRemoved)
    //   }
    // },
  },

  mounted() {
    if (this.selectedVariants.length > 0) {
      this.fetchComboVariants()
      this.variationsList
        .filter(vari => this.selectedVariants.some(name => vari.name === name))
        .forEach(vari => this.addTableField(vari.name, vari.label))
    }
  },

  methods: {
    ...mapActions('pages/catalog/products/productMaintain', [
      'addSku',
      'updateSku',
      'removeSku',
      'setSelectedVariants',
      'fetchComboVariants',
      'getSkuPrices',
    ]),

    getNewPrices() {
      const newPrices = []
      this.product.skus.forEach((sku, index) => {
        this.$refs[`priceTable_${index}`].forEach(priceTable => {
          const skuNewPrices = priceTable.getNewPrices()
          if (skuNewPrices.length) {
            newPrices.push(...skuNewPrices)
          }
        })
      })
      return newPrices
    },

    async getSkuFormData() {
      const formFields = {}
      this.getVariantsSelected.forEach(item => {
        formFields[`variation_${item.id}`] = undefined
      })

      this.localSkuPrices = await this.getSkuPrices(-1)

      this.priceTables.forEach(pt => {
        formFields[`price_${pt.id}`] = undefined
      })
      return formFields
    },

    async showSidebarCreate() {
      this.variationFormData = await this.getSkuFormData()
      this.isUpdate = false
      this.showSidebar = true
    },

    async showSidebarUpdate(row) {
      // console.log(this.getVariantsSelected, row)
      this.skuIndex = row.index
      const formFields = {
        sku: row.item.sku,
        id: row.item.id || null,
      }

      this.getVariantsSelected.forEach(item => {
        const variation = row.item.variations.find(v => v.variationId === item.id)
        formFields[`variation_${item.id}`] =
          variation?.variationValue?.id?.toString() || variation?.variationOpenValue
      })

      this.localSkuPrices = await this.getSkuPrices(this.skuIndex)

      this.localSkuPrices.forEach(p => {
        formFields[`price_${p.priceTable.id}`] = p.price
      })

      this.updateIndex = row.index
      this.isUpdate = true
      this.variationFormData = formFields
      this.showSidebar = true
    },

    addTableField(name, label) {
      const newFields = this.variantsFields
      newFields.push({
        label,
        key: name,
        formatter: (value, key, item) => {
          const variation = item.find(varItem => varItem.variationName === key)
          return variation.variationValue?.value || variation.variationOpenValue
        },
        class: 'text-center',
      })
    },

    removeTableField(name) {
      const index = this.variantsFields.findIndex(field => field.key === name)
      if (index > -1) {
        this.variantsFields.splice(index, 1)

        this.fields = [...this.variantsFields, ...this.baseFields]
      }
    },

    upInsertSku() {
      const formData = this.variationFormData

      const prices = []
      // Update prices
      Object.keys(formData)
        .filter(key => key.startsWith('price_'))
        .forEach(priceKey => {
          const priceTable = this.priceTables.find(
            pt => pt.id === parseInt(priceKey.split('_')[1], 10)
          )
          if (formData[priceKey] > 0) {
            prices.push({
              price: formData[priceKey],
              priceTable,
              store: { id: null },
            })
          }
        })

      if (this.isUpdate) {
        this.updateSku({
          variations: {
            ...formData,
          },
          prices,
          index: this.updateIndex,
        })
        this.$refs.variationsTable.refresh()
      } else {
        this.addSku({
          variations: {
            ...formData,
          },
          prices,
        })
      }

      this.showSidebar = false
      this.variationFormData = {}
    },

    disableChecked(id) {
      return (
        this.product.variations.length > 0 &&
        // eslint-disable-next-line eqeqeq
        this.selectedVariants.some(select => select == id) &&
        this.product.variations.some(vari => !!vari[id])
      )
    },
  },
}
</script>

<style></style>
