<template>
  <div>
    <b-row v-if="invoiceProductName">
      <b-col md="4">
        <FormulateInput
          id="product-invoice-product-name"
          v-model="invoiceProductName"
          :label="$t('Produto da Nota Fiscal')"
          type="label"
        />
      </b-col>
      <b-col md="4">
        <FormulateInput
          id="product-invoice-product-cost"
          v-model="invoiceProductCost"
          :label="$t('Valor de Custo')"
          filter="currency"
          type="label"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <FormulateInput
          id="product-brand-id"
          v-model="product.brandId"
          :label="$t('Marca')"
          :type="isReadOnly ? 'label' : 'select-with-button'"
          :placeholder="$t('Selecione')"
          :options="getComboBrands"
          class="required"
          validation="required"
          :button-permission="$can('Create', 'Brand')"
          @click="showAddSideBar('brand')"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="product-group-id"
          ref="groupCombo"
          v-model="product.groupId"
          :label="$t('Grupo')"
          :type="isReadOnly ? 'label' : 'select-with-button'"
          :placeholder="$t('Selecione')"
          :options="getComboGroups"
          class="required"
          validation="required"
          :button-permission="$can('Create', 'ProductGroup')"
          @click="showGroupSideBar()"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="product-subgroup-id"
          v-model="product.subgroupId"
          :type="isReadOnly ? 'label' : 'select-with-button'"
          :label="$t('Subgrupo')"
          :placeholder="$t('Selecione')"
          :options="getComboSubgroups"
          class="required"
          validation="required"
          :disabled="!product.groupId"
          :button-permission="$can('Create', 'ProductSubgroup')"
          @click="showAddSideBar('subgroup', { groupId: product.group })"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="product-product-line-id"
          v-model="product.productLineId"
          :type="isReadOnly ? 'label' : 'select-with-button'"
          :label="$t('Linha')"
          :placeholder="$t('Selecione')"
          :options="getComboLines"
          class="required"
          validation="required"
          :button-permission="$can('Create', 'ProductLine')"
          @click="showAddSideBar('line')"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="product-department-id"
          v-model="product.departmentId"
          :label="$t('Departamento')"
          :type="isReadOnly ? 'label' : 'select-with-button'"
          :placeholder="$t('Selecione')"
          :options="getComboDepartments"
          class="required"
          validation="required"
          :button-permission="$can('Create', 'ProductDepartment')"
          @click="showAddSideBar('department')"
        />
      </b-col>
      <b-col md="6">
        <FormulateInput
          id="product-product-unit-id"
          v-model="product.productUnitId"
          :label="$t('Unidade')"
          :type="isReadOnly ? 'label' : 'select-with-button'"
          :placeholder="$t('Selecione')"
          :instruction="$t('Classificação da unidade de medida do produto')"
          :options="getComboUnits"
          class="required"
          validation="required"
          :button-permission="$can('Create', 'UnitOfMeasurement')"
          @click="showAddSideBar('unit')"
        />
      </b-col>

      <b-col md="3">
        <FormulateInput
          id="product-product-type"
          v-model="product.productType"
          :label="$t('Tipo')"
          :type="isReadOnly ? 'label' : 'vue-select'"
          placeholder="Selecione"
          validation="required"
          class="required"
          :options="productTypes()"
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="product-classification"
          v-model="product.classification"
          :label="$t('Classificação')"
          :type="isReadOnly ? 'label' : 'vue-select'"
          :placeholder="$t('Selecione')"
          class="required"
          validation="required"
          :options="productClassifications()"
        />
      </b-col>
      <b-col
        v-if="isSaleClassification"
        md="6"
      >
        <FormulateInput
          id="product-lending-product-associated-id"
          v-model="product.lendingProductAssociatedId"
          :type="isReadOnly ? 'label' : 'select-searchable'"
          :label="$t('Comodato Associado')"
          :instructions="[
            {
              text: $t(
                'Produto comodato que será adicionado automaticamente a toda venda deste produto'
              ),
            },
            { text: $t('Digite pelo menos 3 numeros ou letras para iniciar a pesquisa') },
          ]"
          :placeholder="$t('Digite para pesquisar')"
          :options="lendingProducts"
          autofocus
          @fetchSearch="lendingProductsSearch"
        />
      </b-col>
      <b-col
        v-else
        md="6"
      >
        <FormulateInput
          id="product-sale-product-contractual-fine"
          v-model="product.contractualFine"
          :label="$t('Valor indenização por danos')"
          :placeholder="$t('Valor indenização')"
          :instruction="
            $t(
              'Para aluguel e comodato, caso o produto esteja danificado na devolução ou não foi devolvido, será gerada uma venda do item no valor aqui configurado'
            )
          "
          :type="isReadOnly ? 'label' : 'text-number'"
          :filter="'currency'"
          currency="R$"
          :precision="3"
        />
      </b-col>
    </b-row>

    <b-row>
      <!-- <b-col md="6">
        <FormulateInput
          v-model="product.sku"
          id="product-sku"
          :label="$t('SKU')"
          type="text"
          validation="required"
          class="required"
        />
      </b-col> -->
      <b-col
        v-if="showMililiters"
        md="3"
      >
        <FormulateInput
          id="product-milliliters"
          v-model.number="product.milliliters"
          :type="isReadOnly ? 'label' : 'text-number'"
          class="required"
          validation="required|min:1|max:100000|number"
          :label="$t('Mililitros')"
          maxlength="6"
          :instruction="$t('Quantidade de mililitros ofertados por unidade')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="product-active-online"
          v-model="product.activeOnline"
          :type="isReadOnly ? 'status-badge' : 'switch'"
          badge-type="yesNo"
          :label="$t('Vender no Site?')"
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="product-customer-required"
          v-model="product.customerRequired"
          :type="isReadOnly ? 'status-badge' : 'switch'"
          badge-type="yesNo"
          :label="$t('Obriga Cliente?')"
          :instruction="
            $t(
              'Quando selecionado, caso este produto esteja na venda, será obrigatório informar os dados do cliente'
            )
          "
        />
      </b-col>
      <b-col md="2">
        <FormulateInput
          id="product-featured"
          v-model="product.featuredProduct"
          :type="isReadOnly ? 'status-badge' : 'switch'"
          badge-type="yesNo"
          :label="$t('Destacar Produto?')"
          :instruction="
            $t('Quando selecionado, o produto será destacado para venda nos canais online')
          "
        />
      </b-col>
      <b-col md="3">
        <FormulateInput
          id="product-integrate-with-beer-cooler"
          v-model="product.integrateWithBeerCooler"
          :type="isReadOnly ? 'status-badge' : 'switch'"
          badge-type="yesNo"
          :label="$t('Integra Quiosque?')"
          :instructions="[
            {
              text: $t(
                'ATENÇÃO: Não marque esta opção para o produto de Cartão, utilize apenas para os chopes que serão instalados nas torneiras'
              ),
            },
            {
              text: $t(
                'Quando selecionado, este produto é integrado para que possa ser ofertado na chopeira das lojas do tipo Quiosque'
              ),
            },
          ]"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <FormulateInput
          id="product-active-b2b"
          v-model="product.activeB2B"
          :type="isReadOnly ? 'status-badge' : 'switch'"
          badge-type="yesNo"
          :label="$t('Vender no B2B?')"
          :instruction="$t('Quando selecionado, o produto será disponibilizado para venda B2B')"
        />
      </b-col>
      <b-col
        v-if="product.activeB2B"
        md="3"
      >
        <FormulateInput
          id="product-b2b-quantity"
          v-model="product.unitsPerPack"
          :type="isReadOnly ? 'label' : 'text-number'"
          :class="product.activeB2B ? 'required' : ''"
          :label="$t('Qtd. embalagem B2B')"
          :instruction="
            $t(
              'Quantidade que vem na embalagem para venda B2B. Exemplo, para long neck, o pack vem com 6 unidades. Esta é a quantidade mínima na venda B2B e também o múltiplo que será usado (não será permitido quantidades quebradas, apenas múltiplos da quantidade informada neste campo).'
            )
          "
          :validation="product.activeB2B ? 'required' : ''"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <FormulateInput
          id="product-tags"
          v-model="product.tags"
          :type="isReadOnly ? 'label' : 'tags'"
          :label="$t('Tags')"
          :placeholder="$t('Digite a tag e tecle Enter')"
          :size="'lg'"
          :tag-variant="'secondary'"
          :instruction="$t('Digite a tag e tecle Enter')"
          multiple
        />
      </b-col>
    </b-row>
    <product-group-sidebar
      ref="groupSidebar"
      @save="groupCreated"
    />
    <product-subgroup-sidebar
      ref="subgroupSidebar"
      @save="subgroupCreated"
    />
    <brand-sidebar
      ref="brandSidebar"
      @save="brandCreated"
    />
    <product-department-sidebar
      ref="departmentSidebar"
      @save="departmentCreated"
    />
    <product-line-sidebar
      ref="lineSidebar"
      @save="lineCreated"
    />
    <product-unit-sidebar
      ref="unitSidebar"
      @save="unitCreated"
    />
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { productDomains } from '@/mixins'
import ProductGroupSidebar from '../../product-group/ProductGroupSidebar.vue'
import ProductSubgroupSidebar from '../../product-subgroup/ProductSubgroupSidebar.vue'
import BrandSidebar from '../../brand/BrandSidebar.vue'
import ProductDepartmentSidebar from '../../product-department/ProductDepartmentSidebar.vue'
import ProductLineSidebar from '../../product-line/ProductLineSidebar.vue'
import ProductUnitSidebar from '../../product-unit/ProductUnitSidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    ProductGroupSidebar,
    ProductSubgroupSidebar,
    BrandSidebar,
    ProductDepartmentSidebar,
    ProductLineSidebar,
    ProductUnitSidebar,
  },

  mixins: [productDomains],

  props: {
    purchaseOrderProduct: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      lendingProducts: [],
    }
  },

  computed: {
    ...mapState('pages/catalog/products/productMaintain', ['product', 'isReadOnly']),
    ...mapGetters('pages/catalog/products/productMaintain', [
      'productDescription',
      'selectedGroup',
    ]),
    ...mapGetters('pages/catalog/products', [
      'getComboDepartments',
      'getComboLines',
      'getComboUnits',
      'getComboBrands',
      'getComboGroups',
      'getComboSubgroups',
    ]),
    showMililiters() {
      return this.selectedGroup?.hectoliters
    },
    invoiceProductName() {
      return this.purchaseOrderProduct?.xmlProduct?.name
    },
    invoiceProductCost() {
      return (
        this.purchaseOrderProduct?.erpSku?.totalValue /
        (this.purchaseOrderProduct?.erpSku?.quantity || 1)
      )
    },
    isSaleClassification() {
      return this.product.classification === this.productClassificationsEnum.SALE
    },
  },

  mounted() {
    this.fetchProductCombos()
  },

  methods: {
    ...mapActions('pages/catalog/products', [
      'setComboSubgroups',
      'fetchProductCombos',
      'fetchDomainData',
    ]),

    bindGroupListener() {
      this.$refs.groupCombo.$on('input', this.selectGroup)
    },

    selectGroup(idValue) {
      this.product.subgroup = null
      this.setComboSubgroups(idValue)
    },

    showGroupSideBar() {
      this.$refs.groupSidebar.show()
    },

    showAddSideBar(entity, params) {
      this.$refs[`${entity}Sidebar`].show(params)
    },

    async groupCreated(group) {
      await this.fetchDomainData('product-groups')
      this.product.group = group?.id?.toString()
    },

    async subgroupCreated(subgroup) {
      await this.setComboSubgroups(this.product.group)
      this.product.subgroup = subgroup.id.toString()
    },

    async brandCreated(brand) {
      await this.fetchDomainData('brands')
      this.product.brand = brand?.id?.toString()
    },

    async departmentCreated(department) {
      await this.fetchDomainData('departments')
      this.product.department = department?.id?.toString()
    },

    async lineCreated(line) {
      await this.fetchDomainData('lines')
      this.product.productLine = line?.id?.toString()
    },

    async unitCreated(unit) {
      await this.fetchDomainData('units')
      this.product.productUnit = unit?.id?.toString()
    },

    async lendingProductsSearch(loading, search) {
      try {
        loading(true)
        this.lendingProducts = (
          await this.$http.get('/api/products/lending', {
            params: {
              search,
            },
          })
        ).data.results.map(product => ({
          ...product,
          value: product.id,
          label: product.name,
        }))
      } catch (e) {
        this.showError({
          message: this.$t('Não foi possível fazer a pesquisa'),
        })
      } finally {
        loading(false)
      }
    },

    fillDefaultOptions() {
      if (this.product?.id) {
        if (this.product.lendingProductAssociated?.id) {
          const { name, id } = this.product.lendingProductAssociated
          this.lendingProducts = [{ label: name || id, value: id }]
        }
      }
    },
  },
}
</script>

<style></style>
