var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "action-collapse": "",
                    title: _vm.$t("Preços"),
                    "show-loading": _vm.fetching,
                  },
                },
                _vm._l(_vm.product.skus, function (sku, index) {
                  return _c(
                    "div",
                    { key: index, attrs: { sku: sku } },
                    [
                      _c("price-table", {
                        ref: "priceTable_" + index,
                        refInFor: true,
                        attrs: { "sku-index": index, "require-price": false },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "12" } },
            [
              _c(
                "b-card-actions",
                {
                  attrs: {
                    "action-collapse": "",
                    title: _vm.$t("Preços escalonados"),
                    "show-loading": _vm.fetching,
                  },
                },
                _vm._l(_vm.product.skus, function (sku, index) {
                  return _c(
                    "div",
                    { key: index, attrs: { sku: sku } },
                    [
                      _c("tiered-price-table", {
                        ref: "tieredPriceTable_" + index,
                        refInFor: true,
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }